import { useReducer, useEffect } from 'react'
import { Orders } from '@quiqupltd/quiqupjs'
import { OrderInterface } from '../types/order.type'

// Interfaces
interface StateInterface {
  result: OrderInterface | null
  loading: boolean
  error: StateError
}

type StateError = { error: string; status: number } | null

interface OrdersApiInterface {
  order: OrderInterface
}

export interface OrderCreationInterface {
  destinationAddress1: string
  destinationAddress2: string
  destinationContactName: string
  destinationContactPhone: string
  destinationCountry: string
  destinationTown: string
  itemBarcode: string
  itemName: string
  optionIdRequired: boolean
  optionPaymentMethod: string
  optionPaymentMode: string
  optionPaymentAmount: string
  originAddress1: string
  originAddress2: string
  originContactName: string
  originContactPhone: string
  originCountry: string
  originTown: string
  source: string
}

// Constants
enum actions {
  CREATE_INIT = 'CREATE_INIT',
  CREATE_SUCCESS = 'CREATE_SUCCESS',
  CREATE_FAILURE = 'CREATE_FAILURE',
  CREATE_RESET = 'CREATE_RESET',
}

const initialState: StateInterface = {
  result: null,
  error: null,
  loading: false,
}

export enum PAYMENT_MODES {
  PRE_PAID = 'pre_paid',
  PAID_ON_DELIVERY = 'paid_on_delivery',
}

export enum PAYMENT_TYPES {
  CARD = 'card',
  CASH = 'cash',
}

// Helpers
async function createOrder(attr: OrderCreationInterface): Promise<OrdersApiInterface> {
  const modifiedAttr = {
    kind: 'partner_same_day',
    origin: {
      contactName: attr.originContactName,
      contactPhone: attr.originContactPhone,
      address: {
        address1: attr.originAddress1,
        address2: attr.originAddress2,
        town: attr.originTown,
        country: attr.originCountry,
      },
    },
    destination: {
      contactName: attr.destinationContactName,
      contactPhone: attr.destinationContactPhone,
      address: {
        address1: attr.destinationAddress1,
        address2: attr.destinationAddress2,
        town: attr.destinationTown,
        country: attr.destinationCountry,
      },
    },
    items: [
      {
        name: attr.itemName,
        parcelBarcode: attr.itemBarcode,
      },
    ],
    paymentAmount: attr.optionPaymentAmount,
    paymentMode: attr.optionPaymentMode,
    disallowedPaymentTypes: attr.optionPaymentMethod
      ? [PAYMENT_TYPES.CARD, PAYMENT_TYPES.CASH].filter((x) => !attr.optionPaymentMethod.split(',').includes(x))
      : null,
    requiredDocuments: attr.optionIdRequired ? ['customer_identification_photo'] : null,
  }
  return Orders.createOrder(modifiedAttr)
}

function markOrderForCollection(id: string): Promise<OrdersApiInterface> {
  return Orders.markReadyForCollection(id)
}

// Reducer
type Action =
  | { type: actions.CREATE_INIT }
  | { type: actions.CREATE_SUCCESS; response: { order: OrderInterface } }
  | { type: actions.CREATE_FAILURE; error: StateError }
  | { type: actions.CREATE_RESET }

function reducer(state: StateInterface, action: Action): StateInterface {
  switch (action.type) {
    case actions.CREATE_INIT:
      return { ...state, loading: true }
    case actions.CREATE_SUCCESS: {
      const { order } = action.response
      return { ...state, result: order, loading: false }
    }
    case actions.CREATE_FAILURE: {
      return { ...state, loading: false, error: action.error }
    }
    case actions.CREATE_RESET:
      return { ...state, ...initialState }
    default:
      return state
  }
}

// Hook
export function useCreateOrder(attr: OrderCreationInterface, withMarkingForCollection: boolean): StateInterface {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (Object.keys(attr).length === 0) {
      dispatch({ type: actions.CREATE_RESET })
      return
    }

    async function setOrder(): Promise<void> {
      try {
        dispatch({ type: actions.CREATE_INIT })
        const response = await createOrder(attr)
        if (withMarkingForCollection) {
          const response2 = await markOrderForCollection(`${response.order.id}`)
          dispatch({ type: actions.CREATE_SUCCESS, response: response2 })
        } else {
          dispatch({ type: actions.CREATE_SUCCESS, response })
        }
      } catch (error) {
        dispatch({ type: actions.CREATE_FAILURE, error })
      }
    }

    setOrder()
  }, [attr, withMarkingForCollection])

  return state
}
