import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { Form, Button, Timeline, Modal, Result } from 'antd'
import BackButton from '../ui/BackButton'
import Routes from '../../types/routes.type'
import TextField from './TextField'
import SwitchField from './SwitchField'
import RadioField from './RadioField'
import { useCreateOrder, OrderCreationInterface, PAYMENT_MODES, PAYMENT_TYPES } from '../../hooks/create-order'
import { EditOutlined } from '@ant-design/icons'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 50px 30px;
`

const FormContainer = styled.div`
  width: 100%;
  max-width: 1200px;
`
const Title = styled.h1`
  color: ${(p) => p.theme.midnight500};
  font-size: 2rem;
  margin-bottom: 30px;
`

const SubTitle = styled.div`
  color: ${(p) => p.theme.midnight500};
  font-size: 1.5rem;
  margin-bottom: 10px;
`

const MiniTitle = styled.div`
  color: ${(p) => p.theme.midnight500};
  font-size: 1.1rem;
  font-weight: 500;
  margin-left: 20px;
`

const HorizontalEqualDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
`

const HorizontalDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const createBarcode = (): string =>
  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0].map(() => Math.round(Math.random() * 8)).join('')

const DEFAULT_ORDER = {
  destinationAddress1: 'JLT - SHOPPING MALL',
  destinationContactName: 'Rafael Soares',
  destinationContactPhone: '+96111111111',
  destinationCountry: 'UAE',
  destinationTown: 'Dubai',
  itemBarcode: createBarcode(),
  itemName: createBarcode(),
  optionIdRequired: undefined,
  optionPaymentMethod: undefined,
  optionPaymentMode: 'pre_paid',
  optionPaymentAmount: undefined,
  originAddress1: 'Quiqup Delivery LLC',
  originContactName: 'Tim Linsen',
  originContactPhone: '+57222222222',
  originCountry: 'UAE',
  originTown: 'Dubai',
}

function OrderForm(props: RouteComponentProps): JSX.Element {
  const [form] = Form.useForm()
  const [disablePaymentOptions, setDisablePaymentOptions] = useState(false)
  //  eslint-disable-next-line
  const [formValues, setFormValues] = useState({ source: 'oms' } as OrderCreationInterface)
  const order = useCreateOrder(formValues, true)
  const [resultModalVisible, setResultModalVisible] = useState(false)
  const paymentMode = form.getFieldValue('optionPaymentMode')

  //  SUBMIT HANDLER
  function handleSubmit(): void {
    form.validateFields().then((values: OrderCreationInterface) => setFormValues(values))
  }

  useEffect(() => {
    const prepaidSelected = paymentMode === PAYMENT_MODES.PRE_PAID
    setDisablePaymentOptions(prepaidSelected)
    if (prepaidSelected && (form.getFieldValue('optionPaymentAmount') || form.getFieldValue('optionPaymentMethod'))) {
      form.setFieldsValue({
        optionPaymentAmount: undefined,
        optionPaymentMethod: undefined,
      })
    }
  }, [paymentMode, form])

  useEffect(() => {
    setResultModalVisible(
      Object.keys(formValues).length > 0 && !order.loading && (order.result !== null || order.error !== null)
    )
  }, [order, formValues])

  const handleGoHomepage = (): void => {
    props.history.push(Routes.ROOT)
  }

  const handleSubmitAnother = (): void => {
    setResultModalVisible(false)
  }

  const clearFormValues = (): void => {
    //  eslint-disable-next-line
    setFormValues({} as OrderCreationInterface)
  }

  const handlePreFill = (): void => {
    form.setFieldsValue(DEFAULT_ORDER)
  }

  return (
    <Container>
      <BackButton paths={[Routes.ROOT]} />
      <FormContainer>
        <HorizontalDiv>
          <Title>Order submission form</Title>
          <Button onClick={handlePreFill} type="ghost">
            <EditOutlined /> Pre-Fill
          </Button>
        </HorizontalDiv>
        <Form
          layout="horizontal"
          onFinish={handleSubmit}
          data-testid="submission_form"
          name="submission_form"
          form={form}
        >
          <Timeline>
            <Timeline.Item>
              <SubTitle>
                <span role="img" aria-label="Origin">
                  📍
                </span>{' '}
                ORIGIN
              </SubTitle>
              <HorizontalEqualDiv>
                <TextField label="Contact Name" identifier="originContactName" required />
                <TextField label="Contact Phone Number" identifier="originContactPhone" required />
              </HorizontalEqualDiv>
              <MiniTitle>Address</MiniTitle>
              <TextField label="Address Line 1" identifier="originAddress1" required />
              <TextField label="Address Line 2 (optional)" identifier="originAddress2" />
              <HorizontalEqualDiv>
                <TextField label="Town" identifier="originTown" required />
                <TextField label="Country" identifier="originCountry" required />
              </HorizontalEqualDiv>
            </Timeline.Item>
            <Timeline.Item>
              <SubTitle>
                <span role="img" aria-label="Destination">
                  📍
                </span>{' '}
                DESTINATION
              </SubTitle>
              <HorizontalEqualDiv>
                <TextField label="Contact Name" identifier="destinationContactName" required />
                <TextField label="Contact Phone Number" identifier="destinationContactPhone" required />
              </HorizontalEqualDiv>
              <MiniTitle>Address</MiniTitle>
              <TextField label="Address Line 1" identifier="destinationAddress1" required />
              <TextField label="Address Line 2 (optional)" identifier="destinationAddress2" />
              <HorizontalEqualDiv>
                <TextField label="Town" identifier="destinationTown" required />
                <TextField label="Country" identifier="destinationCountry" required />
              </HorizontalEqualDiv>
            </Timeline.Item>
            <Timeline.Item>
              <SubTitle>
                <span role="img" aria-label="Item">
                  📦
                </span>{' '}
                ITEM
              </SubTitle>
              <HorizontalEqualDiv>
                <TextField label="Name" identifier="itemName" required />
                <TextField label="Barcode" identifier="itemBarcode" required />
              </HorizontalEqualDiv>
            </Timeline.Item>
            <Timeline.Item>
              <SubTitle>
                <span role="img" aria-label="Options">
                  🕹
                </span>{' '}
                OPTIONS
              </SubTitle>
              <HorizontalEqualDiv>
                <RadioField
                  label="Payment Mode"
                  identifier="optionPaymentMode"
                  options={[
                    { value: PAYMENT_MODES.PRE_PAID, label: 'Pre Paid' },
                    {
                      value: PAYMENT_MODES.PAID_ON_DELIVERY,
                      label: 'Paid on Delivery',
                    },
                  ]}
                  required
                />
                <RadioField
                  label="Payment Method"
                  identifier="optionPaymentMethod"
                  options={[
                    { value: PAYMENT_TYPES.CARD, label: 'Card' },
                    { value: PAYMENT_TYPES.CASH, label: 'Cash' },
                    {
                      value: `${PAYMENT_TYPES.CARD},${PAYMENT_TYPES.CASH}`,
                      label: 'Both',
                    },
                  ]}
                  required
                  disabled={disablePaymentOptions}
                />
                <TextField
                  label="Payment Amount"
                  identifier="optionPaymentAmount"
                  required
                  disabled={disablePaymentOptions}
                />
              </HorizontalEqualDiv>
              <SwitchField label="ID Required" identifier="optionIdRequired" />
            </Timeline.Item>
          </Timeline>

          <Button type="primary" htmlType="submit" size="large" loading={order.loading} data-testid="ticket-submit">
            Submit
          </Button>
        </Form>
      </FormContainer>
      <Modal visible={resultModalVisible} footer={null} onCancel={handleSubmitAnother} afterClose={clearFormValues}>
        {order.result !== null ? (
          <Result
            status="success"
            title="Successfully Submitted a New Order"
            subTitle={`Order id: ${order.result && order.result.id}`}
            extra={[
              <Button onClick={handleSubmitAnother} key="submit">
                Submit Another
              </Button>,
              <Button onClick={handleGoHomepage} type="primary" key="home">
                Go Homepage
              </Button>,
            ]}
          />
        ) : (
          <Result
            status="error"
            title={`ERROR ${order.error && order.error.status}`}
            subTitle={order.error && order.error.error}
            extra={[
              <Button onClick={handleSubmitAnother} key="submit">
                OK
              </Button>,
            ]}
          />
        )}
      </Modal>
    </Container>
  )
}

export default OrderForm
