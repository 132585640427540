import React from 'react'
import ReactDOM from 'react-dom'
import QuiqupJS from '@quiqupltd/quiqupjs'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/react'
import { loadEnv } from './configEnv'
import packageJson from '../package.json'
import Routes from './Routes'
import theme from './theme'
import UserProvider from './context/user.context'
import enviroment from './enviroment'
import { PageErrorBoundary } from './components/Error/page-error-boundary'

loadEnv().then(async (env) => {
  if (env.SITE_ENV === enviroment.production) {
    Sentry.init({
      dsn: 'https://8ff1b21e68c24dc0b167a59a3a0d0dde@sentry.dev.quiq.ly/25',
      environment: env.SITE_ENV,
      release: `oms@${packageJson.version}`,
    })
  }

  QuiqupJS.config.update({
    api: {
      API_BASE_URL: env.API_URL,
      gatewayUrl: env.API_GATEWAY,
    },
  })

  ReactDOM.render(
    <PageErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag('Error Handler', 'root')
      }}
    >
      <UserProvider>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </UserProvider>
    </PageErrorBoundary>,
    document.getElementById('root')
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister()
